<template>
  <div>
    <template>
      <div class="row upcoming-games-text mt-5 ml-0">
        <div class="col-12 text-left">{{ $t(title) }}</div>
      </div>
      <div class="justify-content-md-left mt-3 grid-wrapp">
        <template v-if="type === 'vod'">
          <vod-cart-event v-for="item in events" :key="item.id" :event="item" />
        </template>
        <template v-else>
          <upcoming-event-card
            v-for="item in events"
            :key="item.id"
            :event="item"
          />
        </template>
      </div>
      <router-link
        :to="rout"
        class="d-flex justify-content-center p-2 w-95 p-2 btn btn-dark m-4 rout-btn"
      >
        {{ $t(buttonText) }}
      </router-link>
    </template>
  </div>
</template>

<script>
import UpcomingEventCard from '../schedule/UpcomingEventCard';
import VodCartEvent from '../schedule/VodCartEvent';

export default {
  name: 'GamesGridComponent',
  components: { VodCartEvent, UpcomingEventCard },
  props: {
    events: Array,
    title: {
      type: String,
      default: 'Upcoming events',
    },
    rout: {
      type: String,
      default: '/schedule',
    },
    type: {
      type: String,
    },
    buttonText: {
      type: String,
      default: 'View Full Schedule',
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/css/colors';
@import 'src/assets/css/mixins';

.upcoming-games-text {
  color: $text-primary;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.75rem;
}

.align-items-stretch {
  overflow: hidden;
}

.grid-wrapp {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  gap: 1.5rem;
  margin-bottom: 3rem;
  @include media-min(max-container) {
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  }
  @include media-max(xs) {
    grid-template-columns: 1fr;
  }
}
.rout-btn {
  background-color: $main-background;
  font-weight: 600;
}
</style>
