<template>
    <div class="wrapper">
        <div class="poster-wrapper" >
            <div class="background" v-if="eventImage" >
                <image-component :path="eventImage"/>
            </div>
            <div class="preview" v-if="previewSrc">
                <image-component :path="previewSrc" :alt="`${event.name} preview`"/>
            </div>
            <div class="center-wrapper">
                <app-link class="action_btn" :to="eventAction">
                    <i v-if="event.has_access" class="fas fa-play"></i>
                    <i v-else class="fas fa-lock"></i>
                </app-link>
            </div>
        </div>
        <div class="item-description">
            <p class="title" :title="event.name">{{event.name}}</p>
            <div>{{formatEventDate}} <span class="timezone">{{timeZoneName}}</span></div>
        </div>
    </div>
</template>

<script>
  import {MEDIA_TYPE, TIME_ZONES} from "@/const";
  import AppLink from "../../../components/base-components/AppLink";
  import ImageComponent from "../../../components/base-components/ImageComponent";


  export default {
    name: "VodCartEvent",
    components: {ImageComponent, AppLink},
    props: {
      event: {
        type: Object
      },
      selectedTimeZone: {
        type: String
      }
    },
    computed: {
      formatEventDate() {
          const {day, month, year } = this.getFormattedDate({
              ts: this.event.event_utc_ts,
              timeZone: this.selectedTimeZone,
              formatsOptions: {
                  month: {month: 'long'},
                  day: {day: '2-digit'},
                  year: {year: 'numeric'}
              }
          })
        return `${day} ${month}, ${year}`
      },
      timeZoneName(){
        return TIME_ZONES.find(({value}) => value === this.selectedTimeZone)?.name || this.selectedTimeZone
      },
      eventImage(){
        return this.event?.media?.find(item => Number(item.media_type) === MEDIA_TYPE.image)?.media_url
      },
      previewSrc(){
        return this.event.media?.find(asset => asset.media_type === MEDIA_TYPE.preview)?.media_url
      },
      eventAction(){
        if (this.event.has_access) {
          return {name:'watch', params: {id: this.event.id}}
        } else if (!this.$store.getters.isLogged){
          return  {name: 'login'}
        } else {
          return {name: 'buy', query:{event_id: this.event.id}}
        }
      }
    },
  }
</script>


<style scoped lang="scss">
    @import "src/assets/css/colors";
    @import "src/assets/css/mixins";

    .wrapper{
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        overflow: hidden;
        background-color: #fff;
        padding: 10px;
        p{
            margin-bottom: 0;
        }
        &:hover{
            .preview{
                display: block;
            }
        }
    }
    .action_btn{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        justify-content: center;
        align-items: center;
        display: flex;
        &:hover{
            text-decoration: none;
        }
        i{
            border: 1px solid $text-secondary;
            padding: 1rem;
            border-radius: 50%;
            color: $text-secondary;
            background: rgba($text-primary-rgb, .5);
        }
    }
    .timezone{
        font-weight: 600;
        height: 2rem;
    }
    .button{
        display: block;
        background: $button-primary;
        font-weight: 700;
        line-height: 1.5;
        font-size: 1.2rem;
        text-transform: uppercase;
        padding: .5rem 1rem;
        border-radius: .4rem;
        color: $text-secondary;
        z-index: 2;
        &:hover{
            text-decoration: none;
            color: $text-secondary
        }
    }
    .center-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .poster-wrapper{
        background-repeat: no-repeat;
        background-size: cover;
        @include ratio(calc(16 / 9));
        flex-grow: 1;
        padding: .46rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }
    .background{
        position: relative;
        z-index: -1;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .status{
        position: absolute;
        top: .46rem;
        left: .46rem;
        width: 1.5rem;
    }
    .preview{
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .item-description{
        text-align: left;
        margin-top: 1rem;
    }
    .title{
        font-weight: 600;
        word-break: break-word;
        margin-bottom: .5rem;
        @include line-clamp(2);
    }
</style>